<template>
  <div id="app">
    <error-alert/>
    <top-bar
      :left-margin="sideBarWidth"
      :height="topBarHeight"
      class="d-none d-lg-flex"
      v-if="!!this.$store.state.profile.uid"
    />
    <navigation
      :side-bar-width="sideBarWidth"
      :bottom-bar-height="bottomBarHeight"
      v-if="!!this.$store.state.profile.uid"
    />
    <view-wrapper
      :left-margin="sideBarWidth"
      :bottom-bar-height="bottomBarHeight"
      :show-navigation="!!this.$store.state.profile.uid"
      :top-margin="topBarHeight"
      v-slot="viewWrapper"
    >
      <keep-alive include="StatusMonitor">
        <router-view
          @set-background-color="viewWrapper.setBackgroundColor"
        />
      </keep-alive>
      <div
        class="h-100"
        v-show="shouldDisplayLoadingUser"
      >
        <loading/>
      </div>
    </view-wrapper>
  </div>
</template>

<script>
import Navigation from './components/navigation/Navigation'
import ViewWrapper from './components/ViewWrapper'
import TopBar from './components/navigation/TopBar'
import Loading from './components/Loading'
import ErrorAlert from '@/components/ErrorAlert'

export default {
  name: 'App',
  components: { ErrorAlert, Loading, TopBar, ViewWrapper, Navigation },
  data () {
    return {
      sideBarWidth: '91px',
      bottomBarHeight: '49px',
      topBarHeight: '82px'
    }
  },
  computed: {
    shouldDisplayLoadingUser () {
      return !(this.$route.name === 'LandingPage' ||
        this.$route.name === 'UserExpired' ||
        this.$route.name === 'WelcomePage' ||
        this.$store.state.profile.retrieved)
    }
  }
}
</script>

<style lang="scss">
@import 'assets/css/kone-icons.css';

#app {
  font-family: 'KONE Information', 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}
@font-face {
  font-family: 'KONE Information';
  src: url("assets/fonts/KONE-Information.woff2") format("woff2")
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
