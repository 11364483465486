
/**
 * @typedef SearchEntry
 * @type {object}
 * @property {string} searchText - Free text used in search.
 * @property {string} date - Timestamp for the search entry in ISOString format.
 */

/**
 * Manages user's search history, by:
 * 1. persisting the search query to local storage when the user searches for
 *    something, and...
 * 2. retrieving the user's previous searches from local storage, so that they
 *    can be offered to the user as suggestions.
 *
 * If a user repeats a search, that search is set as the newest search.
 *
 * @param {string} params.key -
 *   A unique non-empty string to identify a particular set of searches in
 *   local storage.
 * @param {number} params.maxEntries -
 *   A non-negative integer limiting the number of searches that a particular
 *   history can have. If the limit is exceeded, older entries are removed,
 *   until the amount is within the limit again.
 */
class SearchHistory {
  constructor ({ key, maxEntries }) {
    if (typeof key !== 'string' || key === '') {
      throw new TypeError(
        'Expect `key` to be a non-empty string, instead it was: ' + key +
        ' (type: ' + typeof key + ').'
      )
    }
    if (!Number.isInteger(maxEntries) || maxEntries < 0) {
      throw new TypeError(
        'Expect `maxEntries` to be a non-negative integer, instead it was: ' +
        maxEntries + ' (type: ' + typeof maxEntries + ').'
      )
    }
    this._key = key
    this._maxEntries = maxEntries
  }

  add (searchText) {
    if (searchText) {
      const now = new Date()
      const newSearchEntry = this.createEntry({ searchText, date: now })
      const storedSearchHistory = this.retrieve() // Safe to mutate
      const updatedSearchHistory = this._updateSearchHistory(storedSearchHistory, newSearchEntry)
      this._storeSearchHistory(updatedSearchHistory, this._maxEntries)
    }
  }

  /**
   * Get search history from local storage
   *
   * @see createEntry for the structure of returned items.
   * @returns {SearchEntry[]} - List of search entries
   */
  retrieve () {
    let searchHistory = []
    const searchHistoryString = window.localStorage.getItem(this._key)
    if (searchHistoryString) {
      // console.debug({ _: 'SEARCH-HISTORY: found in local storage', searchHistoryString })
      searchHistory = JSON.parse(searchHistoryString)
    }
    // console.debug({ _: 'SEARCH-HISTORY: retrieve', searchHistory })
    return searchHistory
  }

  _byDateString (a, b) {
    const dateA = Date.parse(a.date)
    const dateB = Date.parse(b.date)
    return dateB - dateA
  }

  _storeSearchHistory (searchHistory, maxEntries) {
    let searchHistoryCopy = [...searchHistory]
    searchHistoryCopy.sort(this._byDateString)
    searchHistoryCopy = searchHistoryCopy.slice(0, maxEntries)
    const searchHistoryString = JSON.stringify(searchHistoryCopy)
    window.localStorage.setItem(this._key, searchHistoryString)
  }

  // Will mutate searchHistory
  _updateSearchHistory (searchHistory, newSearchEntry) {
    for (const entry of searchHistory) {
      if (entry.searchText === newSearchEntry.searchText) {
        // Patch the new date for existing search
        entry.date = newSearchEntry.date
        return searchHistory
      }
    }
    // Search string was not found, so let's push it to the top
    searchHistory.push(newSearchEntry)
    return searchHistory
  }

  /**
   * Create a search entry object for storing in the local storage
   *
   * Dates are converted to strings as timestamps are stored to local storage
   * as a JSON string and JSON does not have date type.
   *
   * @param {Object} searchProperties
   * @param {String} searchProperties.searchText - Free text search from user input.
   * @param {Date} searchProperties.date - Date object to determine the timestamp of the searchEntry.
   * @returns {SearchEntry} - Search entry object.
   */
  createEntry ({ searchText, date }) {
    // // console.debug(`typeof searchText ${typeof searchText}`)
    if (!searchText || !(typeof searchText === 'string')) {
      throw Error(`Invalid value "${searchText}" for parameter "searchText"`)
    }
    if (!(date instanceof Date)) {
      throw Error(`Invalid type "${typeof date}" for parameter "date"`)
    }
    // Convert date objects to strings
    const dateString = date.toISOString()
    const searchEntry = { searchText, date: dateString }
    return searchEntry
  }
}

const statusMonitorSearchHistory = new SearchHistory({
  key: 'PartsTrackerUserSearchHistory',
  maxEntries: 10
})
const stockManagementSearchHistory = new SearchHistory({
  key: 'stockManagementSearchHistory',
  maxEntries: 10
})
const materialCountSearchHistory = new SearchHistory({
  key: 'materialCountSearchHistory',
  maxEntries: 10
})

export {
  statusMonitorSearchHistory,
  stockManagementSearchHistory,
  materialCountSearchHistory
}
