/* global firebase */

export const signOutMixin = {
  methods: {
    signOut () {
      this.$store.commit('setInitialState')
      firebase.auth().signOut()
    }
  }
}
