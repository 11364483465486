/* global firebase */
import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import { router, setUrlQueryListener } from './router'
import store from './store'
import axios from 'axios'
import qs from 'qs'
import { APPROVER_EMAIL, CUSTOMER_DOMAINS } from './constants/shared-constants'
import { messages } from '@/utils/strings'

Vue.config.productionTip = false
let vueApp = ''
const MAX_RETRIES = 3

// Hook the URL query parameter listener so that user searches can be reacted to.
setUrlQueryListener(router, store)

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  appID: process.env.VUE_APP_FIREBASE_APP_ID
}
firebase.initializeApp(firebaseConfig)
firebase.auth().onAuthStateChanged(async (user) => {
  if (!user) {
    router.push({ name: 'LandingPage' })
  }
  if (!vueApp) {
    if (user) {
      const email = user.email
      const domain = email.split('@')[1]
      if (CUSTOMER_DOMAINS.includes(domain)) {
        store.commit('setManagerEmail', APPROVER_EMAIL)
        const idToken = await user.getIdToken()
        store.commit('setIdToken', idToken)
        store.commit('setDisplayName', user.displayName)
      } else {
        console.error('Email domain not supported')
        store.commit('setErrorMessage', messages.getLabelEmailDomainNotSupported({ domain }))
      }
    }
    Vue.prototype.$screen = Vue.observable({
      width: window.innerWidth,
      height: window.innerHeight
    })
    window.addEventListener('resize', () => {
      Vue.prototype.$screen.width = window.innerWidth
      Vue.prototype.$screen.height = window.innerHeight
    })
    vueApp = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})

// let tokencount = 0 // this is for simulating status 401
function beforeRequest (config) {
  if (config.url.indexOf(process.env.VUE_APP_USER_API_ENDPOINT) < 0 &&
      config.url.indexOf(process.env.VUE_APP_PARTS_API_ENDPOINT) < 0 &&
      config.url.indexOf(process.env.VUE_APP_STOCK_API_ENDPOINT) < 0) {
    // Only intercept calls to our backend and skip everything else
    return config
  }
  const token = store.getters.idToken
  // this is for simulating status 401
  if (!token) {
    throw new Error('User profile does not have Firebase ID Token.')
  }
  config.headers.Authorization = `Bearer ${token}`
  return config
}

function onError (error) {
  console.error('Axios interceptor onError request handler')
  throw error
}

function sleep (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

async function retryRequest (config, retries = MAX_RETRIES) {
  // create new axios instance to ignore axios response interceptor
  // so it doesn't cause infinite retries if the renewing Token keeps failing
  const retryInstance = axios.create()
  // add request interceptor to new instance so function beforeRequest will insert the new Authorization header
  retryInstance.interceptors.request.use(beforeRequest, onError)
  try {
    return await retryInstance.request(config)
  } catch (error) {
    if (retries) {
      const timeout = 20 ** (4 - retries)
      await sleep(timeout)
      await retryRequest(config, retries - 1)
    } else {
      return Promise.reject(error)
    }
  }
}

axios.defaults.paramsSerializer = function (params) {
  return qs.stringify(params, {
    /*
     * Removes brackets (`[]`) from array parameter names:
     * `?query[]=1&query[]=2` to `?query=1&query=2`
     */
    indices: false
  })
}
axios.interceptors.request.use(beforeRequest, onError)
axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  console.error('Axios interceptor onError response handler')
  if (error.response) {
    if (error.response.status === 401) {
      // access token expired, to renew token
      return firebase.auth().currentUser.getIdToken(true).then(function (resp) {
        vueApp.$store.commit('setIdToken', resp)
        // call the original request again, function beforeRequest will insert the new Authorization header
        return retryRequest(error.config)
      })
    }
    if (error.response.status === 403 && error.response.data.message === 'User expired') {
      vueApp.$router.push('/user-expired')
    }
  }
  return Promise.reject(error)
})

// TODO: Remove this code later!
// This code unregisters the service worker from KPT and can be removed in later release
navigator.serviceWorker.getRegistrations().then(function (registrations) {
  for (const registration of registrations) {
    registration.unregister()
  }
}).catch(function (err) {
  console.log('Unregistering service worker failed: ', err)
})
// END OF TODO
