<template>
  <page-layout
    class="h-100"
  >
    <div
      class="h-100 w-100 d-flex justify-content-center align-items-center"
    >
      <b-card
        class="shadow border-0"
      >
        <b-row
          no-gutters
        >
          <b-col
            align-self="center"
          >
            <b-card-title
              class="text-center"
            >
              {{ messages.getLabelSignIn() }}
            </b-card-title>
            <div
              id="firebaseui-auth-container"
            />
            <div
              v-show="loading"
            >
              <loading/>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </page-layout>
</template>

<script>
/* global firebase, firebaseui */
import { messages } from '@/utils/strings'
import PageLayout from '../components/PageLayout'
import Loading from '../components/Loading'
import { APPROVER_EMAIL, CUSTOMER_DOMAINS } from '../constants/shared-constants'

export default {
  name: 'LandingPage',
  components: { Loading, PageLayout },
  data () {
    return {
      loading: false,
      messages
    }
  },
  computed: {
    profile () {
      return this.$store.state.profile
    }
  },
  mounted () {
    if (!this.profile.uid) {
      this.initFirebaseUi()
    }
  },
  methods: {
    initFirebaseUi () {
      console.debug('Initialising Firebase UI')
      const _self = this
      const uiConfig = {
        callbacks: {
          signInSuccessWithAuthResult: function (authResult, redirectUrl) {
            const email = authResult.user.email
            const domain = email.split('@')[1]
            if (CUSTOMER_DOMAINS.includes(domain)) {
              _self.$store.commit('setManagerEmail', APPROVER_EMAIL)
            }
            _self.loading = true
            _self.$store.commit('setIdToken', authResult.user.h.b)
            _self.$store.commit('setDisplayName', authResult.user.displayName)
            _self.$router.push({ name: 'StatusMonitor' })
            return false
          }
        },
        signInOptions: [
          'microsoft.com',
          firebase.auth.GoogleAuthProvider.PROVIDER_ID
        ]
      }
      if (!window.ui) {
        window.ui = new firebaseui.auth.AuthUI(firebase.auth())
      }
      window.ui.start('#firebaseui-auth-container', uiConfig)
    }
  }
}
</script>

<style scoped lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

#firebaseui-auth-container ::v-deep .firebaseui-container > .firebaseui-card-content > form > .firebaseui-idp-list >
.firebaseui-list-item {
  .firebaseui-idp-button {
    min-width: 235px;
    max-width: 250px;
  }
  .firebaseui-idp-button[data-provider-id="microsoft.com"] {
    background-color: #58AB27 !important;
    .firebaseui-idp-text {
      color: rgb(15, 15, 15);
    }
    .firebaseui-idp-icon {
      background: url(../assets/auth_service_mslive.svg);
      width: 18px;
      height: 18px;
      padding-left: 18px;
      display: block;
      box-sizing: border-box;
      color: white;
    }
  }
}
@include media-breakpoint-down(sm) {
  .shadow {
    box-shadow: none!important;
  }
}
.text-center {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
}
</style>
