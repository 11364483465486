import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import prioritymanager from '@/store/prioritymanager'
import statusMonitor from '@/store/status-monitor'
import stockManagement from '@/store/stock-management.js'
import stockCount from '@/store/stock-count'
import materialCount from '@/store/material-count'
import { MECHANIC, CUSTOM_SERVICE_ADMIN, SUPERVISOR } from '@/constants/permissions'
import { isActionPermittedForPermissions } from '@/constants/permitted-actions'
import { defaultLocale, ensureValidLocale } from '@/constants/locales'
import { messages } from '@/utils/strings'

Vue.use(Vuex)

let hasWarnedAboutDeprecationOfHasCurrentUserPermission = false
let hasWarnedAboutDeprecationOfHasCurrentUserSomeOfPermissions = false

function parseBackendProfile (resp) {
  const profile = {
    licensed: resp.ifLicensed,
    organisation: resp.org,
    uid: resp.UID,
    email: resp.userEmail,
    approvalRequestId: resp.approval_request_id,
    permissions: resp.permissions,
    locale: ensureValidLocale(resp.locale),
    isLocaleLocked: false,
    managedShipToPartyNumbers: resp.managed_ship_to_party_numbers,
    phoneNumber: resp.phone_number,
    expirationDate: resp.expiration_date,
    shipToParty: resp.ship_to_party,
    firstLogin: resp.first_login,
    importantMessage: resp.importantMessage,
    plants: resp.plants,
    plannerGroups: resp.plannerGroups,
    storageLocations: resp.storageLocations,
    workCenters: resp.workCenters
  }
  return profile
}

function parseBackendapprovalRequest (resp) {
  const approvalRequest = {
    requestingUserEmail: resp.requesting_user_email,
    managerEmail: resp.manager_email,
    approvalStatus: resp.approval_status
  }
  return approvalRequest
}

function parseBackendPendingApprovals (item) {
  const newItem = {
    requestingUserEmail: item.requesting_user_email,
    requestId: item.request_id
  }
  return newItem
}

export default new Vuex.Store({
  modules: {
    prioritymanager,
    statusMonitor,
    stockManagement,
    stockCount,
    materialCount
  },
  state: {
    auth: {
      idToken: null
    },
    profile: {
      retrieved: false,
      locale: defaultLocale,
      isLocaleLocked: false,
      approvalRequestId: '',
      uid: '',
      importantMessage: null
    },
    managerEmail: '',
    displayName: null,
    approvalRequest: {},
    suggestions: [],
    pendingApprovals: [],
    totalItems: 0,
    pageCursor: '',
    error: {
      // messageTimeout = 0 means that error message isn't show to the user
      messageTimeout: 0,
      message: ''
    },
    constants: {},
    actionPointCount: undefined,
    arrivedCount: undefined,
    incomingCount: undefined,
    totalCount: undefined,
    // The `query` part of the URL query parameters
    // eg. for URL http://localhost:3000/status-monitor?query=000322741031&cursor=
    // this is '000322741031'
    urlQuery: undefined
  },
  getters: {
    getError (state) {
      if (state.error && state.error.message) {
        return state.error.message
      }
      return null
    },
    idToken (state) {
      if (state.auth && state.auth.idToken) {
        return state.auth.idToken
      }
      return null
    },
    profileLogo (state) {
      if (state.profile.retrieved) {
        if (state.profile.organisation === 'KONE') {
          return messages.getProfileLogoAddress()
        }
      }
      return null
    },
    createMaterialCodeUrl: state => materialCode => {
      if (state.profile.retrieved && materialCode) {
        // TODO: Make links for material code configurable.
        // @see CPL-733
        if (state.profile.organisation === 'KONE') {
          return messages.getLabelMaterialCodeAddress({ materialCode })
        }
      }
      return null
    },
    instructionsLinkUrl (state) {
      if (state.profile.retrieved) {
        if (state.profile.organisation === 'KONE') {
          return messages.getLabelInstructionsLinkAddress()
        }
      }
      return null
    },
    instructionsLinkText (state) {
      if (state.profile.retrieved) {
        if (state.profile.organisation === 'KONE') {
          return messages.getLabelInstructionsLink()
        }
      }
      return null
    },
    getActionPointCount (state) {
      return state.actionPointCount
    },
    getArrivedCount (state) {
      return state.arrivedCount
    },
    getIncomingCount (state) {
      return state.incomingCount
    },
    getTotalCount (state) {
      return state.totalCount
    },
    /**
     * @deprecated -- Use `isPermitted` instead.
     */
    hasCurrentUserPermission: state => permission => {
      // State profile permissions are the permissions current user has in datastore. All available
      // permissions are stored in state.constants/permissions
      if (!hasWarnedAboutDeprecationOfHasCurrentUserPermission) {
        console.warn('`hasCurrentUserPermission` is deprecated, use `isPermitted` instead.')
        hasWarnedAboutDeprecationOfHasCurrentUserPermission = true
      }
      return state.profile.permissions.includes(permission)
    },
    /**
     * @deprecated -- Use `isPermitted` instead.
     */
    hasCurrentUserSomeOfPermissions: state => permissions => {
      if (!hasWarnedAboutDeprecationOfHasCurrentUserSomeOfPermissions) {
        console.warn('`hasCurrentUserSomeOfPermissions` is deprecated, use `isPermitted` instead.')
        hasWarnedAboutDeprecationOfHasCurrentUserSomeOfPermissions = true
      }
      return (permissions.some(permission => state.profile.permissions.includes(permission)))
    },
    /**
     * Checks whether the current user can perform given permitted action.
     *
     * You can also optionally specify the permissionIds, if they are different
     * from the current user's.
     */
    isPermitted: state => (permittedAction, permissionIds) => {
      if (!permissionIds) {
        permissionIds = state.profile.permissions
      }
      return isActionPermittedForPermissions(
        permittedAction, permissionIds
      )
    },
    useMyOrders (state) {
      // Construct list of permissions where Show My Orders should be used.
      const permissionsWithMyOrders = [MECHANIC, CUSTOM_SERVICE_ADMIN, SUPERVISOR]
      //  Get the first matching permission.
      const profilePermission = permissionsWithMyOrders.find((permission) => {
        return state.profile?.permissions?.includes(permission)
      })
      if (profilePermission) {
        // We only care if one of the permissions matches, not wich one it was.
        return true
      }
      // User profile did not have permission listed in `permissionsWithMyOrders`.
      return false
    },
    getManagerEmail (state) {
      return state.managerEmail
    }
  },
  mutations: {
    setPermissions (state, constants) {
      const formatted = {}
      constants.forEach((permission) => {
        formatted[permission.ID] = {
          id: permission.ID,
          label: permission.LABEL,
          description: permission.DESCRIPTION
        }
      })
      state.constants.permissions = formatted
    },
    setIdToken (state, idToken) {
      state.auth.idToken = idToken
    },
    setDisplayName (state, name) {
      state.displayName = name
    },
    setProfile (state, { profile }) {
      const newProfile = parseBackendProfile(profile)
      newProfile.retrieved = true
      state.profile = newProfile
    },
    setLocale (state, locale) {
      state.profile.locale = locale
    },
    setIsLocaleLocked (state, isLocaleLocked) {
      state.profile.isLocaleLocked = isLocaleLocked
    },
    setApprovalRequestID (state, RequestID) {
      state.profile.approvalRequestId = RequestID
    },
    setSuggestions (state, suggestions) {
      state.suggestions = suggestions
    },
    setApprovalRequest (state, approvalRequest) {
      const newRequest = parseBackendapprovalRequest(approvalRequest)
      state.approvalRequest = newRequest
    },
    setPendingApprovals (state, items) {
      state.pendingApprovals = items
    },
    setErrorMessage (state, message) {
      state.error.message = message
      // timeout in seconds
      state.error.messageTimeout = 9
    },
    closeErrorMessage (state, value) {
      state.error.messageTimeout = value
    },
    setActionPointCount (state, value) {
      state.actionPointCount = value
    },
    setArrivedCount (state, value) {
      state.arrivedCount = value
    },
    setIncomingCount (state, value) {
      state.incomingCount = value
    },
    setTotalCount (state, value) {
      state.totalCount = value
    },
    setTrackingStatusCounts (state, response) {
      state.actionPointCount = response.data.count_action_point
      state.arrivedCount = response.data.count_arrived
      state.incomingCount = response.data.count_incoming
      state.totalCount = response.data.count_total
    },
    setInitialState (state) {
      state.auth.idToken = null
      state.profile.retrieved = false
      state.profile.locale = defaultLocale
      state.profile.isLocaleLocked = false
      state.profile.approvalRequestId = ''
      state.profile.uid = ''
      state.approvalRequest = {}
      state.suggestions = []
      state.pendingApprovals = []
      state.totalItems = 0
      state.pageCursor = ''
      state.actionPointCount = 0
      state.arrivedCount = 0
      state.incomingCount = 0
      state.totalCount = 0
    },
    finishFirstLogin  (state) {
      state.profile.firstLogin = false
    },
    setPhoneNumber (state, value) {
      state.profile.phoneNumber = value
    },
    setShipToParty (state, value) {
      state.profile.shipToParty = value
    },
    setUrlQuery (state, urlQuery) {
      // Set the `query` part of the URL parameters so that compoments
      // can react to changes. Watching the $route (and other solutions) as
      // described in the Vue router documentation didn't work for some
      // reason. ( https://router.vuejs.org/guide/essentials/dynamic-matching.html#reacting-to-params-changes )
      state.urlQuery = urlQuery
    },
    saveImportantMessage (state, value) {
      state.profile.importantMessage = value
    },
    deleteImportantMessage (state) {
      state.profile.importantMessage = null
    },
    setManagerEmail (state, managerEmail) {
      state.managerEmail = managerEmail
    }
  },
  actions: {
    getProfile ({ state, commit, dispatch }) {
      return new Promise((resolve) => {
        const url = `${process.env.VUE_APP_USER_API_ENDPOINT}/user_api/v1/user/profile`
        axios.get(url)
          .then((resp) => {
            commit('setProfile', { profile: resp.data })
            let redirect = null
            if (resp.data.approval_request_id) {
              dispatch('getUserApprovalRequest', resp.data.approval_request_id)
              resolve({ name: 'WelcomePage' })
            }
            if (resp.data.first_login) {
              redirect = { name: 'FirstLogin' }
            }
            resolve(redirect)
          })
          .catch((error) => {
            if (error.response.status === 403) {
              if (error.response.data.message === 'User expired') {
                resolve({ name: 'UserExpired' })
              } else {
                resolve({ name: 'WelcomePage' })
              }
            }
          })
      })
    },
    saveLocale ({ commit, dispatch, state }, locale) {
      commit('setIsLocaleLocked', true)
      return new Promise((resolve, reject) => {
        if (state.auth.idToken) {
          const url = `${process.env.VUE_APP_USER_API_ENDPOINT}/user_api/v1/user/profile`
          console.debug('saveLocale: ', locale)
          axios.put(url, { locale })
            .then((resp) => {
              // Update profile after success.
              commit('setProfile', { profile: resp.data })
              resolve()
            })
            .catch((error) => {
              commit('setErrorMessage', error?.response?.data?.message)
              reject(error)
            })
        }
      })
    },
    setLocale ({ commit, dispatch, state }, locale) {
      return new Promise((resolve, reject) => {
        commit('setLocale', locale)
      })
    },
    getConstants ({ commit, state }) {
      if (!state.constants.permissions) {
        return new Promise((resolve, reject) => {
          const url = `${process.env.VUE_APP_USER_API_ENDPOINT}/user_api/v1/constants/permissions`
          axios.get(url)
            .then((resp) => {
              commit('setPermissions', resp.data)
              resolve()
            })
            .catch((error) => {
              console.error(error)
              reject(error)
            })
        })
      } return Promise.resolve()
    },
    getUserApprovalRequest ({ commit, state }, approvalRequestId) {
      if (state.auth.idToken) {
        return new Promise((resolve) => {
          const url = `${process.env.VUE_APP_USER_API_ENDPOINT}/user_api/v1/approval_requests/${approvalRequestId}`
          axios.get(url)
            .then((resp) => {
              commit('setApprovalRequest', resp.data)
            })
            .catch((error) => {
              commit('setErrorMessage', error.response.data.message)
            })
        })
      }
    },
    getUAPSuggestionCountries ({ commit }, { query, maxResults }) {
      return new Promise((resolve) => {
        const url = `${process.env.VUE_APP_USER_API_ENDPOINT}/user_api/v1/tracking/uap/suggestions/countries`
        const params = {
          query,
          maxResults
        }
        axios.get(url, { params })
          .then((resp) => {
            console.debug({ resp })
            commit('setSuggestions', resp.data.items)
            resolve(resp.data.items)
          })
      })
    },
    getPendingApprovals ({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.auth.idToken) {
          const url = `${process.env.VUE_APP_USER_API_ENDPOINT}/user_api/v1/approval_requests/pending`
          axios.get(url)
            .then((resp) => {
              const preparedPendingApprovals = (resp.data || []).map(parseBackendPendingApprovals)
              commit('setPendingApprovals', preparedPendingApprovals)
              resolve()
            })
            .catch((error) => {
              commit('setErrorMessage', error.response.data.message)
              reject(error)
            })
        }
      })
    },
    saveImportantMessage ({ commit, state }, importantMessage) {
      return new Promise((resolve, reject) => {
        if (state.auth.idToken) {
          const url = `${process.env.VUE_APP_PARTS_API_ENDPOINT}/parts/v1/users/important-message`
          axios.post(url, { important_message: importantMessage })
            .then((resp) => {
              commit('saveImportantMessage', resp.data)
              resolve()
            })
            .catch((error) => {
              commit('setErrorMessage', error?.response?.data?.message)
              reject(error)
            })
        }
      })
    },
    deleteImportantMessage ({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.auth.idToken) {
          const url = `${process.env.VUE_APP_PARTS_API_ENDPOINT}/parts/v1/users/important-message`
          axios.delete(url)
            .then(() => {
              commit('deleteImportantMessage')
              resolve()
            })
            .catch((error) => {
              commit('setErrorMessage', error?.response?.data?.message)
              reject(error)
            })
        }
      })
    }
  }
})
