<template>
  <div
    class="view-wrapper h-100"
    :style="{ 'margin-left': leftMargin, 'margin-top': topMargin, 'background-color': backgroundColor }"
    :class="{ 'm-0': !showNavigation }"
  >
    <slot
      :set-background-color="setBackgroundColor"
    />
    <div
      class="bottom-bar-spacer"
      :style="{ 'height': bottomBarHeight }"
    />
  </div>
</template>

<script>
export default {
  name: 'ViewWrapper',
  props: {
    leftMargin: {
      required: true,
      type: String
    },
    bottomBarHeight: {
      required: true,
      type: String
    },
    showNavigation: {
      required: true,
      type: Boolean
    },
    topMargin: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      backgroundColor: ''
    }
  },
  computed: {
    importantMessage () {
      return this.$store.state.profile.importantMessage
    }
  },
  methods: {
    setBackgroundColor (color) {
      this.backgroundColor = color
    }
  },
  watch: {
    $route: function () {
      this.backgroundColor = ''
    },
    importantMessage: function (newValue) {
      if (newValue) {
        // Show a toast at the top of the screen if there is an important message to show.
        // The message is a part of the user profile call in a property 'importantMessage'
        // See Vuex store and getProfile.
        // important-message-toast-id is used so we can hide (or show) the toast. It is used in UserProfile.vue
        // when toast text is saved a new or toast text is deleted.
        this.$bvToast.toast(
          this.importantMessage,
          {
            title: 'Important notice!',
            toaster: 'b-toaster-top-full',
            variant: 'danger',
            solid: true,
            noAutoHide: true,
            id: 'important-message-toast-id'
          }
        )
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@include media-breakpoint-down(md) {
  .view-wrapper {
    margin-left: 0!important;
    margin-top: 0!important;
  }
}
@include media-breakpoint-up(lg) {
  .bottom-bar-spacer {
    height: 0!important;
  }
}
</style>
