/**
 * Collection of functions to extract infromation from tracking status
 */

import { DELIVERED } from '@/constants/shared-constants'

const trackingStatusStages = Object.freeze({
  SERVICE_ORDER: 'ServiceOrder',
  RESERVATION_ITEM: 'ReservationItem',
  DELIVERY_ORDER_ITEM: 'DeliveryOrderItem',
  PURCHASE_ORDER_ITEM: 'PurchaseOrderItem',
  PURCHASE_ORDER_CONFIRMATION_ITEM: 'PurchaseOrderConfirmationItem',
  PURCHASE_ORDER_GOODS_RECEIPT_ITEM: 'PurchaseOrderGoodsReceiptItem',
  SALES_ORDER_ITEM: 'SalesOrderItem',
  SALES_ORDER_SCHEDULE_LINE_ITEM: 'SalesOrderScheduleLineItem',
  UNKNOWN: 'UnknownTrackingStatusStage'
})

/**
 * Get information to display in the order row.
 *
 * This might need to be updated, but currently the logic is that delivery date and location
 * matches the same material code item row.
 * @param {object} trackingStatus- Tracking status-like object.
 * @param {array} trackingStatus.items- Tracking status items-like array.
 */
function getSummaryForOrderRow (trackingStatus) {
  // console.log({ trackingStatus, _: 'getSummaryForOrderRow' })
  let summary = {
    actionPoint: undefined, // Placeholder for displaying actionpoint(s) for order row
    addressCode: null,
    arrMaterialDescription: [],
    deliveryAddress: null,
    deliveryDate: null,
    deliveryDateIsEta: null,
    deliveryOrderDocNumber: null,
    deliveryStatus: null,
    deliveryType: null,
    eta: null,
    materialCode: null,
    materialDescription: null,
    notification: undefined, // Placeholder for displaying actionpoint(s) for order row
    purchaseOrder: null,
    purchaseOrderDocNumber: null,
    purchaseOrderConfirmationCategory: null,
    purchaseOrderConfirmationQuantity: null,
    reservationDocNumber: null,
    salesOrderDocNumber: null,
    salesOrderDocNumberAtPO: null,
    serviceOrder: null,
    stage: null,
    trackingNumber: null,
    transportDetails: []
  }
  const summaryItem = findSummaryItem(trackingStatus.items)
  if (!summaryItem) {
    return summary
  }
  summary = {
    // Set defaults
    ...summary,
    // And fill in info if available
    addressCode: summaryItem.addressCode,
    arrMaterialDescription: summaryItem.arrMaterialDescription,
    deliveryDate: summaryItem.deliveryDate,
    deliveryDateIsEta: deliveryDateIsEta(summaryItem),
    deliveryOrderDocNumber: summaryItem.deliveryOrderDocNumber,
    eta: summaryItem.eta,
    // serviceOrder: trackingStatus.serviceOrder,
    materialCode: summaryItem.materialCode,
    materialDescription: summaryItem.materialDescription,
    // equipmentCode: trackingStatus.equipmentCode,
    ...getDestinationInfo(summaryItem),
    ...getServiceOrderReplacement(summaryItem),
    purchaseOrder: summaryItem.purchaseOrder,
    purchaseOrderDocNumber: summaryItem.purchaseOrderDocNumber,
    purchaseOrderConfirmationCategory: summaryItem.purchaseOrderConfirmationCategory,
    purchaseOrderConfirmationQuantity: summaryItem.purchaseOrderConfirmationQuantity,
    reservationDocNumber: summaryItem.reservationDocNumber,
    salesOrderDocNumber: summaryItem.salesOrderDocNumber,
    salesOrderDocNumberAtPO: summaryItem.salesOrderDocNumberAtPO,
    stage: getTrackingStatusStage(summaryItem),
    trackingNumber: summaryItem.trackingNumber,
    transportDetails: summaryItem.transportDetails
    // TODO: get action point and notification info
  }
  return summary
}
function calculateReservationQuantitySum (trackingStatusItems) {
  let sum = 0
  for (const item of trackingStatusItems) {
    // This is the fix proposal for displaying the correct reservation quantity sums.
    // See: https://verkkoaps.atlassian.net/browse/CPL-261
    if (item.reservationQuantity && item.pending === true) {
      sum += item.reservationQuantity
    }
  }
  return sum
}

function getPurchaseOrderItemQuantity (trackingStatusItems) {
  // The purchaseOrderQuantity should be the same in all items so return it if the _stage of the item matches expected ones.
  for (const item of trackingStatusItems) {
    if (item.purchaseOrderQuantity) {
      return item.purchaseOrderQuantity
    }
  }
}

// salesOrderScheduleLineQuantity tells the confirmed quantity and
// deliveryQuantity tells how much have been delivered already.
// For more information check Structure of OHD and KPT DATA model.
// Both fields are not used in the same item but they are both needed when counting
// the sum as there are no separate sum variables in data.
function calculateSalesOrderScheduleLineAndDeliveryQuantitySum (trackingStatusItems) {
  let sum = 0
  for (const item of trackingStatusItems) {
    if (item.salesOrderScheduleLineQuantity) {
      sum += item.salesOrderScheduleLineQuantity
    }
    if (item.deliveryQuantity) {
      sum += item.deliveryQuantity
    }
  }
  return sum
}

function deliveryDateIsEta (trackingStatusItem) {
  if (trackingStatusItem.deliveryStatus === DELIVERED) {
    return false
  }
  if (trackingStatusItem.eta) {
    // TODO: Check if logic is correct.
    return true
  }
  return false
}

/**
 * Find the sub time to use in order row information.
 * @param {array} trackingStatusItems - Tracking status sub item-like objects.
 * @returns {object} - Summary to be used in order row.
 */
function findSummaryItem (trackingStatusItems) {
  // TODO: Make some logic to find the most useful row. Next ETA? Latest delivery? Action points? Notifications?
  // Is this defined somewhere?
  // If there is only one item, use that.
  if (trackingStatusItems.length === 1) {
    return trackingStatusItems[0]
  }
  let summaryItem = null
  for (const item of trackingStatusItems) {
    // Try to get something to return
    summaryItem = item
    // console.log({ item })
    if (item.deliveryDate || item.eta) {
      // Simple placeholder logic: Find first item that has some date set.
      break
    }
  }
  return summaryItem
}

function getDestinationInfo (trackingStatusItem) {
  return {
    deliveryType: trackingStatusItem.deliveryType ?? null,
    deliveryAddress: trackingStatusItem.deliveryAddress || null,
    deliveryStatus: trackingStatusItem.deliveryStatus ?? null,
    storageLocation: trackingStatusItem.storageLocation ?? null
  }
}

// get service order replacement if service order and equipment code are missing for summary row
function getServiceOrderReplacement (trackingStatusItem) {
  return {
    mainAssetNumber: trackingStatusItem.mainAssetNumber,
    network: trackingStatusItem.network,
    costCenter: trackingStatusItem?.costCenter
  }
}

/**
 * Determine the stage of the tracking status.
 *
 * TODO: In future this will probably come from the backend.
 *
 * @see https://app.lucidchart.com/documents/edit/999de7e3-8a31-48bb-9b36-2cefcd923772/1eelgPoUOGKz
 *   For documentation
 * @param {object} trackingStatusItem - TrackingStatusItem-like object (sub item).
 * @returns {string} - Name of the stage the TrackingStatus currently is.
 */
function getTrackingStatusStage (trackingStatusItem) {
  function missing (value) {
    // Helper function to make the logic below more readable
    if (value === null || value === undefined) {
      return true
    }
    return false
  }
  function hasValue (value) {
    // Helper function to make the logic below more readable
    return !missing(value)
  }

  // ServiceOrder Stage (1)
  if (
    hasValue(trackingStatusItem.serviceOrder) &&
    missing(trackingStatusItem.reservationDocNumber) &&
    missing(trackingStatusItem.purchaseOrderDocNumber) &&
    missing(trackingStatusItem.purchaseOrderConfirmationCategory) &&
    missing(trackingStatusItem.purchaseOrderGoodsReceiptQuantity) &&
    missing(trackingStatusItem.salesOrderDocNumber) &&
    missing(trackingStatusItem.salesOrderScheduleLineQuantity) &&
    missing(trackingStatusItem.deliveryOrderDocNumber)
  ) {
    return trackingStatusStages.SERVICE_ORDER
  }

  // ReservationItem Stage (2)
  if (
    hasValue(trackingStatusItem.reservationDocNumber) &&
    missing(trackingStatusItem.purchaseOrderDocNumber) &&
    missing(trackingStatusItem.purchaseOrderConfirmationCategory) &&
    missing(trackingStatusItem.purchaseOrderGoodsReceiptQuantity) &&
    missing(trackingStatusItem.salesOrderDocNumber) &&
    missing(trackingStatusItem.salesOrderScheduleLineQuantity) &&
    missing(trackingStatusItem.deliveryOrderDocNumber)
  ) {
    return trackingStatusStages.RESERVATION_ITEM
  }

  // PurchaseOrderItem Stage (3)
  if (
    hasValue(trackingStatusItem.purchaseOrderDocNumber) &&
    missing(trackingStatusItem.purchaseOrderConfirmationCategory) &&
    missing(trackingStatusItem.purchaseOrderGoodsReceiptQuantity) &&
    missing(trackingStatusItem.salesOrderDocNumber) &&
    missing(trackingStatusItem.salesOrderScheduleLineQuantity) &&
    missing(trackingStatusItem.deliveryOrderDocNumber)
  ) {
    return trackingStatusStages.PURCHASE_ORDER_ITEM
  }
  // PurchaseOrderConfirmationItem Stage (4)
  if (
    hasValue(trackingStatusItem.purchaseOrderConfirmationCategory) &&
    missing(trackingStatusItem.deliveryOrderDocNumber) &&
    missing(trackingStatusItem.salesOrderScheduleLineQuantity)
  ) {
    return trackingStatusStages.PURCHASE_ORDER_CONFIRMATION_ITEM
  }
  // purchaseOrderGoodsReceiptItem Stage (5)
  if (
    hasValue(trackingStatusItem.purchaseOrderGoodsReceiptQuantity) &&
    missing(trackingStatusItem.salesOrderScheduleLineQuantity) &&
    missing(trackingStatusItem.deliveryOrderDocNumber)
  ) {
    return trackingStatusStages.PURCHASE_ORDER_GOODS_RECEIPT_ITEM
  }
  // SalesOrderItem Stage (6)
  if (
    hasValue(trackingStatusItem.salesOrderDocNumber) &&
    missing(trackingStatusItem.salesOrderScheduleLineQuantity) &&
    missing(trackingStatusItem.deliveryOrderDocNumber)
  ) {
    return trackingStatusStages.SALES_ORDER_ITEM
  }

  // SalesOrderScheduleLineItem Stage (7)
  if (
    hasValue(trackingStatusItem.salesOrderScheduleLineQuantity) &&
    missing(trackingStatusItem.deliveryOrderDocNumber)
  ) {
    return trackingStatusStages.SALES_ORDER_SCHEDULE_LINE_ITEM
  }

  // DeliveryOrderItem Stage (8)
  if (
    hasValue(trackingStatusItem.deliveryOrderDocNumber)
  ) {
    return trackingStatusStages.DELIVERY_ORDER_ITEM
  }

  // Stage did not match any definition. This might be OK, but right now let's just log a warning.
  console.warn({ _: 'getTrackingStatusStage fell trough', trackingStatusItem })
  return trackingStatusStages.UNKNOWN
}

function isLogisticsFlowLocated ({ deliveryType }) {
  if (
    deliveryType === 'Warehouse' ||
    deliveryType === 'AccessPoint'
  ) {
    return true
  }
  return false
}

/**
 * Determine what information to show in the order (summary) row
 *
 * Should show either:
 *  A) Equipment code or description and service order.
 *  B) Material code and description and other fallback information.
 *
 * @param {object} param
 * @param {string|null} param.equipmentCode - Equipment Code from TrackingStatus
 * @param {string|null} param.serviceOrder - Service Order from TrackingStatus
 * @returns {boolean} - Should the equipment info used. If false, use fallback.
 */
function showEquipmentInformation ({ equipmentCode, serviceOrder }) {
  if (equipmentCode || serviceOrder) {
    // It is unclear what this means in context, but the logic specs say:
    //
    //   if equipmentCode or serviceOrder:
    //      show serviceOrder;
    //      show equipmentDescription or equipmentCode
    //   else:
    //     show materialCode + materialDescription;
    //     show mainAssetNumber or network or costCenter
    //
    // See https://verkkoaps.atlassian.net/browse/CPL-296
    return true
  }
  return false
}

/**
 * Get fallback information for order (summary) row
 *
 * @param {object} orderRowSummary - See `getSummaryForOrderRow`
 * @returns {object} - Object with string properties `name` and `value`
 */
function getServiceOrderFallback (orderRowSummary) {
  // There are cases where Service Order Items are missing in the SAP data (unable to locate) or
  // the Service Order or Equipment Info fields are empty in the SAP.
  // In those cases we use serviceOrderFallback
  // as a fallback to show best alternative information in place of serviceOrder.
  for (const property of ['mainAssetNumber', 'network', 'costCenter', 'salesOrderDocNumberAtPO']) {
    const value = orderRowSummary[property]
    if (value) {
      // Return the first property which is not empty.
      return { name: property, value }
    }
  }
  // return empty strings if none of ['mainAssetNumber', 'network', 'costCenter', 'salesOrderDocNumberAtPO'] has value
  return { name: '', value: '' }
}

export {
  getSummaryForOrderRow,
  calculateReservationQuantitySum,
  getPurchaseOrderItemQuantity,
  calculateSalesOrderScheduleLineAndDeliveryQuantitySum,
  deliveryDateIsEta,
  getTrackingStatusStage,
  isLogisticsFlowLocated,
  trackingStatusStages,
  showEquipmentInformation,
  getServiceOrderFallback
}
