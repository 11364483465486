import axios from 'axios'
import moment from 'moment'
import {
  CANCELLED,
  CANCELLED_VISIBLE_TO_USER,
  COMPLETED_VISIBLE_TO_USER,
  MECHANIC,
  MY_MATERIAL,
  MY_STOCK_COUNT,
  PLANT,
  SINGLE,
  STORAGE_LOCATION,
  WALL_TO_WALL,
  WORK_CENTER
} from '@/constants/stock-count'
import { removeUppercaseEmails } from './helpers'

const DEFAULT_MAX_RESULTS = 20
const DEFAULT_PARAMETERS = 'maxResults=20&page=1'

function listStockCountItems (queryParameters) {
  const requiredParameters = ['query', 'type', 'maxResults', 'page']
  requiredParameters.forEach(parameter => {
    if (queryParameters[parameter] === undefined) {
      throw Error(`Missing mandatory parameter ${parameter} in listStockCountItems`)
    }
  })
  const endpoint = '/api/v2/my_stock_count'
  const url = process.env.VUE_APP_STOCK_API_ENDPOINT + endpoint
  const requestPromise = axios.get(url, { params: queryParameters })
    .catch(error => {
      console.error({ _: 'listStockCountItems failed', queryParameters, url, error })
      throw error
    })
  return requestPromise
}

function listItems (queryParameters) {
  const { itemType, ...params } = queryParameters
  let url = process.env.VUE_APP_STOCK_API_ENDPOINT + '/api/v2/'
  if (itemType === MECHANIC) {
    url += `relationship/${itemType}?workCenter=${params.workCenter}`
  } else if (itemType === PLANT) {
    url += 'relationship/' + PLANT
  } else if (itemType === MY_MATERIAL) {
    const { searchValue } = queryParameters
    url += `${itemType}?${DEFAULT_PARAMETERS}&materialCode=${searchValue}`
  } else {
    url += `relationship/${itemType}?${PLANT}=${params.plant}`
  }
  const requestPromise = axios.get(url)
    .catch(error => {
      console.error({ _: `list ${itemType}s failed`, queryParameters, url, error })
      throw error
    })
  return requestPromise
}

function createStockCount (parameters) {
  const url = process.env.VUE_APP_STOCK_API_ENDPOINT + '/api/v2/my_stock_count'
  const requestPromise = axios.post(url, parameters)
    .catch(error => {
      console.error({ _: 'create Stock count failed', parameters, url, error })
      throw error
    })
  return requestPromise
}

function submitStockCount (parameters) {
  const { stockCountId } = parameters
  const url = process.env.VUE_APP_STOCK_API_ENDPOINT + `/api/v2/my_stock_count/submit?stockCountId=${stockCountId}`
  const requestPromise = axios.get(url)
    .catch(error => {
      console.error({ _: 'submit Stock Count failed', parameters, url, error })
      throw error
    })
  return requestPromise
}

function cancelStockCount (parameters) {
  const { stockCountId } = parameters
  const url = process.env.VUE_APP_STOCK_API_ENDPOINT + '/api/v2/my_stock_count'
  const requestPromise = axios.put(url, { stockCountId, status: CANCELLED })
    .catch(error => {
      console.error({ _: 'cancelling Stock Count failed' }, parameters, url, error)
      throw error
    })
  return requestPromise
}

function saveEditedStockCount (parameters) {
  const url = process.env.VUE_APP_STOCK_API_ENDPOINT + '/api/v2/my_stock_count'
  const requestPromise = axios.put(url, parameters)
    .catch(error => {
      console.error({ _: 'editing Stock Count failed' }, parameters, url, error)
      throw error
    })
  return requestPromise
}

const statusMapping = {
  cancelled: 'Cancelled',
  in_progress: 'In progress',
  error: 'Error',
  initiated: 'Initiated',
  submitted: 'Submitted',
  completed: 'Completed',
  recount: 'Recount'
}

function statusToShow (status) {
  if (statusMapping[status]) {
    return statusMapping[status]
  }
  return 'Unknown'
}

function stockCountTypeToShow (type) {
  const typeMapping = { [SINGLE]: 'Single', [WALL_TO_WALL]: 'Wall To Wall' }
  if (typeMapping[type]) {
    return typeMapping[type]
  }
  return 'Unknown'
}

function parseBackendStockCountItems (item) {
  const createdFormatted = moment(item.created).format('lll')
  const shouldBeDoneAt = moment(item.should_be_done_at).format('ll')
  const status = statusToShow(item.status)
  const type = stockCountTypeToShow(item.type)
  return {
    id: item.id,
    created: createdFormatted,
    created_by: item.created_by,
    plant: item.plant,
    type: type, // Do note that this type is different than the state.type
    should_be_done_at: shouldBeDoneAt,
    status: status,
    storage_location: item.storage_location,
    conducted_by: item.conducted_by,
    work_center: item.work_center
  }
}

export default {
  namespaced: true,
  state: {
    defaultMaxResults: DEFAULT_MAX_RESULTS,
    firstFetchDone: false,
    materialCodes: [],
    mechanics: [],
    ongoingQuery: false,
    orderByColumn: 'plant',
    page: 0,
    plants: [],
    quantityEditingDisabled: true,
    query: [],
    sortDirection: 'asc',
    stockCountItems: [],
    storageLocations: [],
    submissionSuccessfulTitle: '',
    submissionFailedTitle: '',
    type: MY_STOCK_COUNT, // MY_STOCK_COUNT OR MY_MATERIAL_COUNT
    workCenters: [],
    currentStockCount: null
  },
  getters: {
    currentStockCount (state) {
      return state.currentStockCount
    },
    /**
     * Has there been at least one call to Stock Count API for listing stock items
     *
     * This helps to determine when the page is loaded.
     *
     * @param {Object} state - Vuex state for getters.
     * @returns {boolean} - True after first request to Stock API is finished.
     */
    firstFetchDone: (state) => {
      return state.firstFetchDone
    },
    /**
     * Indicates if front-end is currently calling Stock API to list stock items
     *
     * @param {Object} state - Vuex state for getters.
     * @returns {boolean} - True if request has been made but response has not arrived.
     */
    ongoingQuery: (state) => {
      return state.ongoingQuery
    },
    stockCountItems: (state) => page => {
      return state.stockCountItems[page]?.stockCountItems ?? []
    },
    mobileStockCountItems: (state) => {
      let mobileItems = []
      for (const itemPage of state.stockCountItems) {
        mobileItems = [...mobileItems, ...itemPage.stockCountItems]
      }
      return mobileItems
    },
    page: (state) => {
      return state.page
    },
    lastPage: (state) => {
      if (state.stockCountItems.length === 0) {
        return null
      }
      const lastPage = state.stockCountItems?.[state.stockCountItems.length - 1]
      return lastPage
    },
    hasNext: (state, getters) => {
      const currentPage = state.stockCountItems[getters.page]
      if (currentPage) {
        return currentPage.hasNext
      }
      return false
    },
    hasPrev: (state, getters) => {
      const currentPage = state.stockCountItems[getters.page]
      if (currentPage) {
        return currentPage.hasPrev
      }
      return false
    },
    getQuantityEditingDisabled (state) {
      return state.quantityEditingDisabled
    },
    storageLocations (state) {
      return state.storageLocations
    },
    workCenters (state) {
      return state.workCenters
    },
    plants (state) {
      return state.plants
    },
    materialCodes (state) {
      return state.materialCodes
    },
    mechanics (state) {
      return state.mechanics
    },
    sortDirection (state) {
      if (state.sortDirection === 'desc') { // false is ascending and true is descending order
        return true
      }
      return false
    },
    orderByColumn (state) {
      return state.orderByColumn
    },
    defaultMaxResults (state) {
      return state.defaultMaxResults
    },
    submissionSuccessfulTitle (state) {
      return state.submissionSuccessfulTitle
    },
    submissionFailedTitle (state) {
      return state.submissionFailedTitle
    }
  },
  mutations: {
    setOrderByColumn (state, value) {
      state.orderByColumn = value
    },
    setSortDirection (state, value) {
      if (value) { // false is ascending and true is descending order
        state.sortDirection = 'desc'
      } else {
        state.sortDirection = 'asc'
      }
    },
    setDisableQuantityEditing (state, value) {
      state.quantityEditingDisabled = value
    },
    addItems (state, { response, queryParameters }) {
      const { itemType } = queryParameters
      if (itemType === STORAGE_LOCATION) {
        state.storageLocations = response.data.storage_locations
      } else if (itemType === WORK_CENTER) {
        state.workCenters = response.data.work_centers
      } else if (itemType === PLANT) {
        state.plants = response.data.plants
      } else if (itemType === MY_MATERIAL) {
        const materialCodes = response.data.my_material_items.map(material => {
          return {
            name: material.material_code,
            value: material.material_code
          }
        })
        state.materialCodes = materialCodes
      } else if (itemType === MECHANIC) {
        state.mechanics = removeUppercaseEmails(response.data.mechanics)
      }
    },
    addStockCountPage (state, { queryParameters, response }) {
      const matchingQueryPages = state.stockCountItems.filter(page => page.query === queryParameters.query)
        .filter(page => page.type === queryParameters.type)
        .filter(page => page.maxResults === queryParameters.maxResults)
      if (matchingQueryPages > 0) {
        const matchingPage = matchingQueryPages.find(page => page.page === queryParameters.page)
        if (matchingPage) {
          throw Error('addStockCountPage tried to add a page that has already been fetched. Cache mismatch.')
        }
      }
      const newPage = {
        index: state.stockCountItems.length,
        query: queryParameters.query,
        type: queryParameters.type,
        page: response.data.page,
        pages: response.data.pages,
        hasNext: response.data.has_next,
        hasPrev: response.data.has_prev,
        stockCountItems: response.data.stock_count_items.map(parseBackendStockCountItems)
      }
      state.stockCountItems.push(newPage)
      state.page = newPage.index
    },
    clearStockCount (state) {
      state.page = 0
      state.firstFetchDone = false
      state.stockCountItems = []
    },
    startQuery (state) {
      state.ongoingQuery = true
    },
    finishQuery (state, changeFirstFetchDone = true) {
      state.firstFetchDone = true && changeFirstFetchDone
      state.ongoingQuery = false
    },
    setPage (state, page) {
      if (page === undefined || page === null) {
        throw Error('Missing required parameter "page" in setPage')
      }
      if (page !== parseInt(page)) {
        // Rudimentary value check
        throw Error(`Invalid value ${page} for page parameter in setPage`)
      }
      if (page > state.stockCountItems.length) {
        throw Error(`Provided page value ${page} is out of bound for last page index ${state.stockCountItems.length}`)
      }
      state.page = page
    },
    changeStockCountStatus (state, parameters) {
      const { stockCountId, status } = parameters
      for (let i = 0; i < state.stockCountItems.length; ++i) {
        const index = state.stockCountItems[i].stockCountItems.findIndex(item => item.id === stockCountId)
        if (index !== -1) {
          state.stockCountItems[i].stockCountItems[index].status = status
          break
        }
      }
    },
    saveEditedStockCount (state, parameters) {
      const { stockCountId } = parameters
      // You might wonder why is this so complicated, the answer is because of the pagination.
      for (let i = 0; i < state.stockCountItems.length; ++i) {
        const index = state.stockCountItems[i].stockCountItems.findIndex(item => item.id === stockCountId)
        if (index !== -1) {
          state.stockCountItems[i].stockCountItems[index].conducted_by = parameters.conductedBy
          state.stockCountItems[i].stockCountItems[index].work_center = parameters.workCenter
          break
        }
      }
    },
    clearMechanics (state) {
      state.mechanics = []
    },
    setSubmissionSuccessfulTitle (state, value) {
      state.submissionSuccessfulTitle = value
    },
    setSubmissionFailedTitle (state, value) {
      state.submissionFailedTitle = value
    },
    setCurrentStockCount (state, value) {
      state.currentStockCount = value
    },
    setCurrentStockCountStatus (state, value) {
      state.currentStockCount.status = value
    }
  },
  actions: {
    /**
     * Fetch the next page using the page information from last response
     *
     * If there is at least one page and that page hasNext is true (meaning that there are more
     * pages to fetch), then we can fetch next page and continue the pagination.
     *
     * @param {ActionContext} [vuexContext]
     * @returns {Promise} - Raw Tracking Status response from the backend
     *  (passed by fetchPage action)
     */
    fetchPage ({ commit }, queryParameters) {
      commit('startQuery')
      const requestPromise = listStockCountItems(queryParameters)
        .then(response => {
          commit('addStockCountPage', { queryParameters, response })
          return response
        })
        .catch(error => {
          commit('setErrorMessage', error?.response?.data?.message ?? error, { root: true })
        })
        .finally(() => {
          commit('finishQuery')
        })
      return requestPromise
    },
    fetchNextPage ({ state, dispatch, getters }, maxResults) {
      let queryParameters = {}
      if (getters.lastPage) {
        queryParameters = {
          query: getters.lastPage.query ?? [],
          page: getters.lastPage.page + 1,
          type: getters.lastPage.type,
          maxResults: maxResults ?? getters.lastPage.maxResults ?? DEFAULT_MAX_RESULTS,
          orderByColumn: state.orderByColumn,
          sortDirection: state.sortDirection
        }
      } else {
        queryParameters = {
          query: [],
          page: state.page + 1,
          type: state.type,
          maxResults: maxResults ?? DEFAULT_MAX_RESULTS,
          orderByColumn: state.orderByColumn,
          sortDirection: state.sortDirection
        }
      }
      return dispatch('fetchPage', queryParameters)
    },
    fetchStockCountItem ({ state, commit }, stockCountId) {
      const queryParameters = {
        query: [],
        page: 1,
        type: state.type,
        maxResults: 1,
        orderByColumn: state.orderByColumn,
        sortDirection: state.sortDirection,
        stockCountId: stockCountId
      }
      commit('startQuery')
      const requestPromise = listStockCountItems(queryParameters)
        .then(response => {
          commit('setCurrentStockCount', parseBackendStockCountItems(response.data.stock_count_items[0]))
          return response
        })
        .catch(error => {
          commit('setErrorMessage', error?.response?.data?.message ?? error, { root: true })
        })
        .finally(() => {
          commit('finishQuery', false)
        })
      return requestPromise
    },
    fetchItems ({ commit }, queryParameters) {
      commit('startQuery')
      const requestPromise = listItems(queryParameters)
        .then(response => {
          commit('addItems', { queryParameters, response })
          return response
        })
        .catch(error => {
          commit('setErrorMessage', error?.response?.data ?? error, { root: true })
        })
        .finally(() => {
          commit('finishQuery')
        })
      return requestPromise
    },
    fetchItemsNoLoading ({ commit }, queryParameters) {
      const requestPromise = listItems(queryParameters)
        .then(response => {
          commit('addItems', { queryParameters, response })
          return response
        })
        .catch(error => {
          commit('setErrorMessage', error?.response?.data ?? error, { root: true })
        })
      return requestPromise
    },
    createStockCountRequest ({ commit }, parameters) {
      commit('startQuery')
      const requestPromise = createStockCount(parameters)
        .then(response => {
          return response
        })
        .catch(error => {
          commit('setErrorMessage', error?.response?.data ?? error, { root: true })
        })
        .finally(() => {
          commit('finishQuery')
        })
      return requestPromise
    },
    submitStockCount ({ commit }, parameters) {
      commit('startQuery')
      const requestPromise = submitStockCount(parameters)
        .then(response => {
          parameters.status = COMPLETED_VISIBLE_TO_USER
          commit('changeStockCountStatus', parameters)
          return response
        })
        .catch(error => {
          console.error(error?.response?.data)
          commit('setErrorMessage', error?.response?.data ?? error, { root: true })
        })
        .finally(() => {
          commit('finishQuery')
        })
      return requestPromise
    },
    cancelStockCount ({ commit }, parameters) {
      commit('startQuery')
      const requestPromise = cancelStockCount(parameters)
        .then(response => {
          parameters.status = CANCELLED_VISIBLE_TO_USER
          commit('changeStockCountStatus', parameters)
          return response
        })
        .catch(error => {
          console.error(error?.response?.data)
          commit('setErrorMessage', error?.response?.data ?? error, { root: true })
        })
        .finally(() => {
          commit('finishQuery')
        })
      return requestPromise
    },
    saveEditedStockCount ({ commit }, parameters) {
      commit('startQuery')
      const requestPromise = saveEditedStockCount(parameters)
        .then(response => {
          commit('saveEditedStockCount', parameters)
          return response
        })
        .catch(error => {
          console.error(error?.response?.data)
          commit('setErrorMessage', error?.response?.data ?? error, { root: true })
        })
        .finally(() => {
          commit('finishQuery')
        })
      return requestPromise
    }
  }
}
