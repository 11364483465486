<template>
  <b-list-group
    class="list"
    :style="{ width: width }"
  >
    <b-list-group-item
      class="list-item text-center rounded-0"
      v-for="(item, key) in items"
      :key="key"
      :to="item.to"
    >
      <i
        class="icon text-grey"
        :class="[item.icon, { 'text-white': selectedItem(item.to.name) }]"
      />
      <div
        class="text text-grey text-uppercase"
        :class="{ 'text-white': selectedItem(item.to.name) }"
      >
        {{ item.text }}
      </div>
    </b-list-group-item>
    <b-list-group-item
      class="list-item text-center rounded-0"
      button
      @click="signOut"
    >
      <i
        class="icon text-grey icon-exit"
      />
      <div
        class="text text-grey text-uppercase"
      >
        {{ messages.getTabheaderLogout() }}
      </div>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import { messages } from '@/utils/strings'
import { navMixin } from './nav-mixin'
import { signOutMixin } from '../sign-out-mixin'
export default {
  name: 'SideBar',
  mixins: [navMixin, signOutMixin],
  props: {
    items: {
      required: true,
      type: Array
    },
    width: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      messages
    }
  }
}
</script>

<style scoped lang="scss">
.list {
  height: 100%;
  position: fixed;
  top: 0;
  background-color: #202020;
  z-index: 1040;
}
.text {
  font-size: 12px;
}
.icon {
  font-size: 26px;
}
.list-item {
  background-color: #373737;
  border-radius: 0;
  border-bottom: 1px solid #707070;
  padding-left: 0;
  padding-right: 0;
}
.list-item:hover, .list-item:active, .list-item:focus {
  background-color: $primary;
}
.text-grey {
  color: $gray-500;
}
</style>
