<template>
  <div>
    <side-bar
      :items="filteredItems"
      :width="sideBarWidth"
      class="d-none d-lg-block"
    />
    <bottom-bar
      :items="filteredItems"
      :height="bottomBarHeight"
      class="d-lg-none"
    />
  </div>
</template>

<script>
import { messages } from '@/utils/strings'
import BottomBar from './BottomBar'
import SideBar from './SideBar'
import {
  PM_READ, PM_UPLOAD_ADD, PM_UPLOAD_DISABLE, PM_WRITE, UM_PM_GRANT_READ,
  UM_PM_GRANT_WRITE, UM_USER_ACCEPTANCE, SUPER_MANAGER, SUPER_MANAGER_READ_ONLY
} from '@/constants/permissions'
import { stockManagement, userManagement } from '@/constants/permitted-actions'

export default {
  name: 'Navigation',
  components: { SideBar, BottomBar },
  props: {
    sideBarWidth: {
      required: true,
      type: String
    },
    bottomBarHeight: {
      required: true,
      type: String
    }
  },
  computed: {
    items () {
      const items = [
        {
          icon: 'icon-status-monitor',
          text: messages.getTabHeaderStatus(),
          to: { name: 'StatusMonitor' }
        },
        {
          icon: 'icon-priority-call',
          text: messages.getTabHeaderPriority(),
          to: { name: 'PriorityManager' }
        },
        // Note: "inentory" is a typo in the icon pack, not this UI code.
        {
          icon: 'icon-inentory',
          text: messages.getTabHeaderStock(),
          to: { name: 'StockManagement' }
        },
        // Left here on purpose, because KONE asked to hide the navigation until pilot is done and they allow everyone to use stock-count.
        // {
        //   icon: 'icon-circle-plus',
        //   text: messages.getTabHeaderNotifications(),
        //   to: { name: 'Notifications' }
        // },
        {
          icon: 'icon-people',
          text: messages.getTabHeaderUsers(),
          to: { name: 'UsersManagement' }
        },
        {
          icon: 'icon-user',
          text: messages.getTabHeaderProfile(),
          to: { name: 'UserProfile' }
        },
        {
          icon: 'icon-info',
          text: messages.getTabHeaderInfo(),
          to: { name: 'HelpPage' }
        }
      ]
      return items
    },
    filteredItems () {
      let items = this.items
      if (!this.$store.getters.hasCurrentUserSomeOfPermissions([
        PM_READ, PM_WRITE, PM_UPLOAD_ADD, PM_UPLOAD_DISABLE,
        UM_PM_GRANT_READ, UM_PM_GRANT_WRITE, UM_USER_ACCEPTANCE, SUPER_MANAGER,
        SUPER_MANAGER_READ_ONLY
      ])
      ) {
        items = items.filter(item => item.to.name !== 'PriorityManager')
      }
      if (!this.$store.getters.isPermitted(stockManagement.get)) {
        items = items.filter(item => item.to.name !== 'StockManagement')
      }
      if (!this.$store.getters.isPermitted(userManagement.get)) {
        items = items.filter(item => item.to.name !== 'UsersManagement')
      }
      return items
    }
  }
}
</script>

<style scoped>
</style>
