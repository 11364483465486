import axios from 'axios'
import { trackingStatusItemsToRows } from '@/components/StatusMonitor/tracking-status-rows'
import { STATUS_ALL, STATUS_ARRIVED, STATUS_INCOMING } from '@/constants/shared-constants'
import { statusMonitorSearchHistory } from '@/utils/search-history'

const DEFAULT_MAX_RESULTS = 20

function validStatus (status) {
  const allowedStatuses = [STATUS_ALL, STATUS_ARRIVED, STATUS_INCOMING]
  return allowedStatuses.includes(status)
}

/**
 * List Tracking Statuses from Parts API
 *
 * @param {Object} queryParameters
 * @param {String|null} queryParameters.query - Free text search string. If empty or null,
 *   the Tracking Statuses are listed without using document search in the backend.
 * @param {String} queryParameters.status - Status of tracking. @see 'shared-constants.js'
 * @param {Boolean} queryParameters.myOrders - Include only user's own orders.
 * @param {Number} queryParameters.maxResults - (Integer) Limit how many items are at most
 *   in the response.
 * @param {number} queryParameters.page (Integer) current page index in stock items array. Add one to get the database page.
 */
function listTrackingStatuses (queryParameters) {
  const uid = 'me' // UID is currently not used in listing.
  const url = `${process.env.VUE_APP_PARTS_API_ENDPOINT}/parts/v1/users/${uid}/tracking-status`
  const parameters = ['query', 'page', 'status', 'myOrders', 'maxResults']
  parameters.forEach((parameter) => {
    // Check that caller has not forgot to include required parameter.
    if (queryParameters[parameter] === undefined) {
      throw Error(`Missing parameter ${parameter} in listTrackingStatuses`)
    }
  })
  if (!validStatus(queryParameters.status)) {
    throw Error(`Invalid value "${queryParameters.status}" for parameter status`)
  }
  const requestPromise = axios.get(url, { params: queryParameters })
    .catch((error) => {
      console.error({ _: 'listTrackingStatuses failed', queryParameters, url, error })
      throw error
    })
  return requestPromise
}

/**
 * @typedef {object} TrackingStatusesPage
 * @description A page of tracking status results.
 * @property {boolean} hasNext - Whether there are more pages.
 * @property {boolean} hasPrev - Whether there are pages before this one.
 * @property {number} index - Page number starting from 0.
 * @property {number} maxResults - Maximum number of results in a single page.
 * @property {boolean} myOrders - Whether the query is only for the user's orders.
 * @property {number} page - Page number starting from 1.
 * @property {number} pages - The total number of pages.
 * @property {string} query - The search query.
 * @property {string} status - Specifies the value of the "pending" parameter.
 * @property {object[]} trackingStatus - Tracking Status (legacy) parent items from the response.
 */

export default {
  namespaced: true,
  state: {
    userSearchOptions: {
      status: STATUS_ALL,
      myOrders: true,
      maxResults: DEFAULT_MAX_RESULTS
    },
    searchText: null, // This stores the user submitted free text search.
    selectedPage: 0,
    ongoingQuery: false,

    /** @type {TrackingStatusesPage[]} */
    currentPages: [],
    firstFetchDone: false // True after the first Tracking Status listing request has been made
  },
  getters: {
    /**
     * Transform responses from Parts API Beta V2 to mor friendly structure
     *
     * This will concatenate all fetched pages to one list and use
     * `trackingStatusItemsToRows` to transform the items to format used in
     * mobile view.
     *
     * @param {Object} state - Vuex state for getters.
     * @returns {Array} - List of enriched and restructured Tracking Status
     *   items to be used in the Mobile view.
     * @see trackingStatusItemsToRows
     */
    materialGroupTrackingStatuses: (state) => {
      const trackingStatuses = []
      for (const page of state.currentPages) {
        trackingStatuses.push(...page.trackingStatus)
      }
      return trackingStatusItemsToRows(trackingStatuses)
    },
    trackingStatusPage: (state) => (page) => {
      if (page !== parseInt(page)) {
        throw Error(`Parameter "page" must be integer. Was "${page}".`)
      }
      if (page < 0) {
        throw Error(`Parameter "page" must be 0 or more. Was "${page}".`)
      }
      if ((state.currentPages?.length ?? 0) < 0) {
        throw Error('Tried to fetch a page when there are no pages in currentPages.')
      }
      if (page > state.currentPages.length - 1) {
        throw Error(`Index of fetched page ${page} must smaller than current pages length ${state.currentPages.length}.`)
      }
      // console.log({ _: 'trackingStatusPage', page, currentPages: state.currentPages, raw: state.currentPages[page].trackingStatus })
      return trackingStatusItemsToRows(state.currentPages[page].trackingStatus)
    },
    /**
     * Return the last page of Tracking Status items
     *
     * @param {Object} state - Vuex state for getters.
     * @returns {TrackingStatusesPage|null} -
     *   Page object with raw Tracking Status items from the response and
     *   additional metadata. This is the last/latest fetched page or in case
     *   where there are no pages, `null`. Note that a search result with no
     *   items is still a page with no items. One situation where there are no
     *   Tracking Status pages is when the page is loaded and the first request
     *   (search) has not returned anything.
     */
    lastPage: (state) => {
      if (state.currentPages.length === 0) {
        return null
      }
      const lastPage = state.currentPages[state.currentPages.length - 1]
      return lastPage
    },
    selectedPage: (state) => {
      return state.selectedPage
    },
    /**
     * Indicator if a request can be made to fetch more Tracking Status items
     *
     * @param {Object} state - Vuex state for getters.
     * @param {Object} getters - Vuex getters.
     * @returns {boolean} - True if there are more pages.
     *   FIXME: Exception for this rule is that this will also return true if there are
     *   no pages at all. This means that the first request when the page is loaded has
     *   not returned any results. This might be used in those situations to trigger
     *   the inital search request. It would be better to check separately if the first
     *   fetch has not been made.
     * @see firstFetchDone
     */
    hasNextPage: (state, getters) => {
      const lastPage = getters.lastPage
      if (lastPage === null) {
        // Placeholder if nothing is loaded
        // FIXME: Remove this check and use pages and/or other means to determine
        // if the initial fetch in page load must be done.
        return true
      }
      if (lastPage?.hasNext) {
        return true
      }
      return false
    },
    /**
     * Indicates if front-end is currently calling Parts API to list Tracking Statuses
     *
     * @param {Object} state - Vuex state for getters.
     * @returns {Boolean} - True if request has been made but response has not arrived.
     */
    ongoingQuery: (state) => {
      return state.ongoingQuery
    },
    /**
     * Has there been at least one call to Parts API for listing Tracking Statuses
     *
     * This helps to determine when the page is loaded and search should or should not be
     * made without user action.
     *
     * @param {Object} state - Vuex state for getters.
     * @returns {Boolean} - True after first request to Parts API Tracking Statuses is finished.
     */
    firstFetchDone: (state) => {
      return state.firstFetchDone
    },
    searchText: (state) => {
      return state.searchText
    },
    userSearchOptions: (state) => {
      return state.userSearchOptions
    }
  },
  mutations: {
    addTrackingStatusPage (state, { queryParameters, response }) {
      const matchingQueryPages = state.currentPages.filter((page) => page.query === queryParameters.query)
        .filter((page) => page.status === queryParameters.status)
        .filter((page) => page.myOrders === queryParameters.myOrders)
        .filter((page) => page.maxResults === queryParameters.maxResults)
      if (matchingQueryPages.length > 0) {
        // There is a query and pagination in process with those queryParameters.
        // Now let's see if the page index has already been cached.
        const matchingPage = matchingQueryPages.find(page => page.page === queryParameters.page)
        if (matchingPage) {
          // Page has already been cached. Something went wrong. Before making a call the the backend it must be
          // checked that the page does not already exist in cahced pages. If we want to explicitly fetch that
          // page again, the currentPages should be cleared first.
          throw Error('addTrackingStatusPage tried to add a page that has already been fetched. Cache mismatch.')
        }
      } else {
        // No matching search query found, so let's clear the page cache
        state.currentPages = []
      }
      // Add the page to the list. If query matched this will append to the existing list.
      const newPage = {
        index: state.currentPages.length,
        query: queryParameters.query,
        status: queryParameters.status,
        myOrders: queryParameters.myOrders,
        maxResults: queryParameters.maxResults,
        page: response.data.page,
        pages: response.data.pages,
        hasNext: response.data.has_next,
        hasPrev: response.data.has_prev,
        trackingStatus: response.data.trackingStatus
      }
      state.currentPages.push(newPage)
      // Set the current page to point to the newly added page. Components don't have to respect this value.
      state.selectedPage = newPage.index
    },
    clearCurrentPages (state) {
      state.currentPages = []
      state.selectedPage = null
    },
    startQuery (state) {
      state.ongoingQuery = true
    },
    finishQuery (state) {
      state.ongoingQuery = false
      state.firstFetchDone = true // This is set to false only in the "page load" (default)
    },
    setSearchText (state, searchText) {
      state.searchText = searchText ?? null
    },
    setSearchOptionStatus (state, status) {
      if (!validStatus(status)) {
        throw Error(`Invalid value ${status} for search option "status"`)
      }
      state.userSearchOptions = {
        ...state.userSearchOptions,
        status
      }
    },
    setSearchOptionMyOrders (state, myOrders) {
      if (typeof myOrders !== 'boolean') {
        throw Error(`Invalid value ${myOrders} for search option "myOrders"`)
      }
      state.userSearchOptions = {
        ...state.userSearchOptions,
        myOrders
      }
    },
    setSelectedPage (state, page) {
      if (page === undefined || page === null) {
        throw Error('Missing required parameter "page" in setSelectedPage')
      }
      if (page !== parseInt(page)) {
        // Rudimentary value check
        throw Error(`Invalid value ${page} for page parameter in setSelectedPage`)
      }
      if (page > state.currentPages.length) {
        throw Error(`Provided page value ${page} is out of bound for last page index ${state.currentPages.length}`)
      }
      state.selectedPage = page
    }
  },
  actions: {
    /**
     * List Tracking Statuses from Parts API and add results as a new page
     *
     * Use `search` and `fetchNextPage` actions when possible. This is just a wrapper
     * to `listTrackingStatuses` function that sets the `ongoingQuery` flag and adds
     * the results to the Tracking Status collection `currentPages`.
     *
     * @see search
     * @see fetchNextPage
     * @param {ActionContext} [vuexContext]
     * @returns {Promise} - Raw Tracking Status response from the backend
     *  (passed by listTrackingStatuses function)
     */
    fetchPage ({ commit }, queryParameters) {
      commit('startQuery')
      const requestPromise = listTrackingStatuses(queryParameters)
        .then((response) => {
          commit('addTrackingStatusPage', { queryParameters, response })
          // Tracking status counts are in global state so we must use root: true.
          // FIXME: Move tracking status counts to this namespace.
          commit('setTrackingStatusCounts', response, { root: true })
          return response
        })
        .catch(error => {
          commit('setErrorMessage', error?.response?.data?.message ?? error, { root: true })
        })
        .finally(() => {
          commit('finishQuery')
        })
      return requestPromise
    },
    /**
     * Fetch the next page.
     *
     * This function does not take any parameters, but instead reuses the
     * parameters from the initial search.
     *
     * @see listTrackingStatuses
     *
     * @param {ActionContext} [vuexContext]
     * @returns {Promise} - Raw Tracking Status response from the backend
     *  (passed by fetchPage action)
     */
    fetchNextPage ({ state, dispatch, getters }) {
      if (state.currentPages.length === 0) {
        // TODO: This is a placeholder to test the first fetch. Include all options in future.
        return dispatch('search', {})
      }
      const queryParameters = {
        query: getters.lastPage.query,
        page: getters.lastPage.page + 1,
        status: getters.lastPage.status,
        myOrders: getters.lastPage.myOrders,
        maxResults: getters.lastPage.maxResults
      }
      return dispatch('fetchPage', queryParameters)
    },
    /**
     * Fetch the first page of a search
     *
     * Start a new search and pagination process. If there were results (pages) stored in
     * Vuex, they will be cleared. After the first page is fetched, you can dispatch
     * action `fetchNextPage` to continue getting new items.
     *
     * The search options will be filled in using the `userSearchOptions` if the optional
     * parameters are not provided. This is the preferred use of this action.
     *
     * @see listTrackingStatuses action how the response is handled.
     *
     * Returns a promise with the raw response (passed from fetchPage action). It
     * should be not be necessary to use the data inside the response directly, but it might
     * be useful to see the response metadata such as the response code.
     *
     * @param {ActionContext} [vuexContext]
     * @param {Object} payload
     * @param {String|null} payload.query - Free text search string. If empty or null,
     *   the Tracking Statuses are listed without using document search in the backend.
     * @param {String} [payload.status] - Status of tracking. Default value is using userSearchOptions state.
     *   @see 'shared-constants.js'
     * @param {Boolean} [payload.myOrders] - Include only user's own orders. Default value is using
     *   userSearchOptions state.
     * @param {Number} [payload.maxResults] - (Integer) Limit how many items are at most in the response.
     *   Default value is using userSearchOptions state.
     * @param {Boolean} [payload.saveToSearchHistory=true] - Save free text used for search to local storage.
     * @returns {Promise} - Raw Tracking Status response from the backend
     *  (passed by fetchPage action)
     */
    search (
      { state, getters, commit, dispatch },
      { query, status, myOrders, maxResults, saveToSearchHistory, extraParams = {} } = {}
    ) {
      if (getters.ongoingQuery) {
        // When calling this action check that there is no ongoing query.
        // TODO: There should be something to reset ongoingQuery if something goes
        // wrong. Like the response never arrives. Add timeout check or something
        // to the axios call.
        console.error({
          _: 'statusMonitor/search action called when query was ongoing',
          query,
          status,
          myOrders,
          maxResults
        })
        throw Error('Query is still in progress!')
      }
      const queryParameters = {
        query,
        page: 1, // Search can't start with an existing state.
        status: status ?? state.userSearchOptions.status,
        myOrders: myOrders ?? state.userSearchOptions.myOrders,
        maxResults: maxResults ?? state.userSearchOptions.maxResults,
        ...extraParams
      }
      // Clear cached pages so that they user doesn't accidentally see wrong
      // information.
      commit('clearCurrentPages')
      // By default save search to local store history, but only if the search text is not empty.
      const searchKeywords = []
      for (const [key, value] of Object.entries(extraParams)) {
        searchKeywords.push(`${key}=${value}`)
      }
      if ((saveToSearchHistory ?? true) && (query || searchKeywords.length > 0)) {
        const history = [...searchKeywords]
        if (query) {
          history.push(query)
        }
        statusMonitorSearchHistory.add(history.join('&'))
      }
      // Store the search free text for showing indicator that the results are
      // from a search. Query can be empty.
      if (query) {
        commit('setSearchText', [...searchKeywords, query].join(' & '))
      } else {
        commit('setSearchText', searchKeywords.join(' & '))
      }
      return dispatch('fetchPage', queryParameters)
    }
  }
}
